import React from 'react';
import './styles.css';
import Store from './../../../image/store.jpeg'

const Home = () => {

  return (
    <>
    <div className='f1_company' id='link2'>
      <img src={Store} alt='' />
      <div>
        <h4>店舗情報</h4>
        <div>
          <h5>店舗名</h5>
          <h6>pâtisserie taupe（パティスリー トープ）</h6>
        </div>
        <div>
          <h5>住所</h5>
          <h6>神奈川県川崎市川崎区大師河原 2-5-3</h6>
        </div>
        <div>
          <h5>営業時間</h5>
          <h6>11:00~17:00</h6>
        </div>
        <div>
          <h5>定休日</h5>
          <h6>月曜日・火曜日</h6>
        </div>
        <div>
          <h5>電話番号</h5>
          <h6>044-201-9771</h6>
        </div>
        <div>
          <h5>Instagram</h5>
          <h6>@taupe_daishibashi</h6>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;
