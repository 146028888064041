import React from 'react';
import './styles.css';
import Cafe from './../../../image/cafe.jpeg'

const Home = () => {

  return (
    <>
    <div className='f2_company_title'>
      <h4>×</h4>
      <h5>カフェ & バーが2階に併設</h5>
    </div>
    <div className='f2_company'>
      <img src={Cafe} alt='' />
      <div>
        <h4>café vin soleil</h4>
        <p>営業日　水〜日　11:00〜17:00</p>
        {/* <p>　　　　土　11:00〜21:00</p> */}
        <p>定休日／月曜日・火曜日</p>
        <p>〒210-0811 神奈川県川崎市川崎区大師河原2-5-3<br />トゥルモンドビル2階</p>
      </div>
    </div>
    </>
  );
}

export default Home;
