import React from 'react';
import './styles.css';
import Icon from './../../../image/icon.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Home = () => {

  return (
    <>
    <div className='nav'>
      <img src={Icon} alt='' />
      <div>
        <AnchorLink href="#link1" offset="150">
          <p className='nav_mini'>メニュー</p>
        </AnchorLink>
        <AnchorLink href="#link2" offset="150">
          <p className='nav_mini'>店舗情報</p>
        </AnchorLink>
        <p className='nav_onlineshop'>online shop</p>
      </div>
    </div>
    </>
  );
}

export default Home;
