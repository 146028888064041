// export PATH="$HOME/.npm-global/bin:$PATH"
// import "./styles/style.scss"
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';

import Home from './component/index';

function App() {
  return (
    <>
    <BrowserRouter>
      <Route exact path="/" component={Home} />
    </BrowserRouter>
    </>
  );
}

export default App;
