import React, { useState, useEffect } from "react";
import firebase from 'firebase/compat/app'
import './styles.css';

const Home = () => {

  const [ news1, setNews1  ] = useState([]);
  const [ news2, setNews2  ] = useState([]);
  const [ news3, setNews3  ] = useState([]);

  useEffect(() => {
    firebase.firestore().collection("news").doc('items').get()
    .then((doc) => {
      setNews1(doc.data()['news①']);
      setNews2(doc.data()['news②']);
      setNews3(doc.data()['news③']);
    });
  }, [])

  return (
    <>
    {news1.length !== 0 && news2.length !== 0 && news3.length !== 0 ?
      <div className='news'>
        <h2>news</h2>
        <div>
          <h4>
            {new Date(news1[0].seconds * 1000).getFullYear()}年
            {new Date(news1[0].seconds * 1000).getMonth() + 1}月
            {new Date(news1[0].seconds * 1000).getDate()}日
          </h4>
          <h5>{news1[1]}</h5>
        </div>
        <div>
          <h4>
            {new Date(news2[0].seconds * 1000).getFullYear()}年
            {new Date(news2[0].seconds * 1000).getMonth() + 1}月
            {new Date(news2[0].seconds * 1000).getDate()}日
          </h4>
          <h5>{news2[1]}</h5>
        </div>
        <div>
          <h4>
            {new Date(news3[0].seconds * 1000).getFullYear()}年
            {new Date(news3[0].seconds * 1000).getMonth() + 1}月
            {new Date(news3[0].seconds * 1000).getDate()}日
          </h4>
          <h5>{news3[1]}</h5>
        </div>
      </div>
    : <></> }
    </>
  );
}

export default Home;
