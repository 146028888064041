import React from 'react';
import './styles.css';

const Home = () => {

  return (
    <>
    <div className='footer'>
      <p className='footer_logo'>@pâtisserie taupe</p>
      <p className='footer_saikoutiku'>このwebサイトは事業再構築補助金によって、作成されました。</p>
    </div>
    </>
  );
}

export default Home;
