import React from 'react';
import './styles.css';
import { AiFillCaretRight } from "react-icons/ai"
import Item1 from './../../../image/online.jpeg'

const Home = () => {

  return (
    <>
    <div className='online'>
      <img src={Item1} alt='' />
      <div className='online_title'>
        <div className='online_title_left'>
          <div className='online_title_box'></div>
          <h4>online shop Menu</h4>
        </div>
        <h5>当店のお菓子を遠方やご自宅へお届けいたします。</h5>
        <p>当店だけでなく、自宅、遠方でも楽しめる商品を展開しています。オンライン商品は以下のボタンから詳しい情報が確認できます。ご注文お待ちしております。</p>
        <div className='online_title_btn'>
          オンラインショップ準備中
          <div><AiFillCaretRight className='online_title_btn_icons' /></div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;
