import React from 'react';
import './styles.css';
import DocumentMeta from 'react-document-meta';
import Nav from './home/nav/index'
import Header from './home/header/index'
import News from './home/news/index'
import Calendar from './home/calendar/index'
import Strong from './home/strong/index'
import Menu from './home/menu/index'
import Online from './home/online/index'
import F1 from './home/1F/index'
import F2 from './home/2F/index'
import Footer from './home/footer/index'

const Home = () => {

  const meta = {
    title: 'pâtisserie taupe（パティスリー トープ）',
    description: 'フランス菓子を中心に親しみやすいお菓子をご用意しているケーキ屋で、オンライン販売も実施してますので、遠方でもご注文可能です。また、店舗の２階には、カフェ&バーも併設しているので、ケーキだけなく飲み物もお楽しみいただけます。',
    canonical: 'https://trankllc.com',
    meta: { charset: 'utf-8', }
  };

  return (
    <>
    <DocumentMeta {...meta}>
      <Nav />
      <Header />
      <div className='news_calnder'>
        <News />
        <Calendar />
      </div>
      <Strong />
      <Online />
      <Menu />
      <F1 />
      <F2 />
      <Footer />
    </DocumentMeta>
    </>
  );
}

export default Home;
