import React from 'react';
import './styles.css';
import Item1 from './../../../image/menu/1.jpeg'
import Item2 from './../../../image/menu/2.jpeg'
import Item3 from './../../../image/menu/3.jpeg'
import Item4 from './../../../image/menu/4.jpeg'
import Item5 from './../../../image/menu/5.jpeg'
import Item6 from './../../../image/menu/6.jpeg'

const Home = () => {

  return (
    <>
    <div className='menu_title' id="link1">
      <h4>pâtisserie taupe Menu</h4>
      <h5>人気のメニュー</h5>
    </div>
    <div className='menu'>
      <div>
        <img src={Item1} alt='' />
        <p>グランガトー<br />定番から季節ものまで</p>
      </div>
      <div>
        <img src={Item2} alt='' />
        <p>プチガトー<br />ムースやタルトなど、バリエーション豊か</p>
      </div>
      <div>
        <img src={Item3} alt='' />
        <p>焼菓子<br />焼き色にもこだわってます</p>
      </div>
      <div>
        <img src={Item4} alt='' />
        <p>詰め合わせギフト<br />贈り物やお土産に</p>
      </div>
      <div>
        <img src={Item5} alt='' />
        <p>コーヒー<br />豆にこだわった深煎りコーヒー</p>
      </div>
      <div>
        <img src={Item6} alt='' />
        <p>ワイン<br />ケーキとのペアリングもオススメ</p>
      </div>
    </div>
    </>
  );
}

export default Home;
