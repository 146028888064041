import React from 'react';
import './styles.css';
import HeaderPage from './../../../image/heaader_logo.png'
import Slider from './slider/index'

const Home = () => {

  return (
    <>
    <div className='header'>
      <div className='header_left'>
        <img src={HeaderPage} alt='' />
        <p>フランス菓子を中心に<br />親しみやすいお菓子をご用意いたします。</p>
      </div>
      <div className='header_right'><Slider /></div>
    </div>

    <div className='header_mini'>
      <div className='header_slider_mini'><Slider /></div>
      <div className='header_box'>
        <div className='header_box_background'></div>
        <div className='header_box_items'>
          <img src={HeaderPage} alt='' />
          <p>フランス菓子を中心に<br />親しみやすいお菓子をご用意いたします。</p>
        </div>
      </div>
    </div>
    </>
  );
}

export default Home;
