import React from 'react';
import './styles.css';

const Home = () => {

  return (
    <>
    <div className='strong'>
      <div className='strong_posttion'></div>
      <div className='strong_text'>
        <h4>imagination</h4>
        <h5>
          自分へのご褒美やちょっとしたおやつ<br />
          大切な人への贈り物、一生に一度きりの記念日に<br />
        </h5>
        <p>
          さまざまなシーンでお使いいただけるよう<br />
          ひとつひとつ丁寧にお作りしています。</p>
      </div>
    </div>
    </>
  );
}

export default Home;
