import './styles.css';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from './../../../../image/slider/1.jpeg';
import Image2 from './../../../../image/slider/2.jpeg';
import Image3 from './../../../../image/slider/3.jpeg';

export default class SlickGoTo extends React.Component {

  state = {
    slideIndex: 0,
    updateCount: 0
  };

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      afterChange: () => this.setState(state => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    };

    return (
      <>
      <Slider ref={slider => (this.slider = slider)} {...settings} className='recruit_slider'>
        <div>
          <img src={Image1} className='recruit_slider_image' alt='' />
        </div>
        <div>
          <img src={Image2} className='recruit_slider_image' alt='' />
        </div>
        <div>
          <img src={Image3} className='recruit_slider_image' alt='' />
        </div>
      </Slider>
      </>
    );
  }
}