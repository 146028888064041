import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/compat/app"
import './index.css';
import "firebase/storage";
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyAE6ulm2DBTVlWN_lckYHRBLErdc_i88Ng",
  authDomain: "taupeweb-a6648.firebaseapp.com",
  projectId: "taupeweb-a6648",
  storageBucket: "taupeweb-a6648.appspot.com",
  messagingSenderId: "1027537673211",
  appId: "1:1027537673211:web:97b45bd5161515a014895e",
  measurementId: "G-CTXX67NRJV"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const analytics = firebase.analytics();

ReactDOM.render(
  <>
  <App />
  </>,
  document.getElementById('root')
);

reportWebVitals();

export { storage, analytics, firebase as default };
